<template>
  <b-overlay :show="showOverlay">
    <b-card>
      <b-form
        class="mb-5 "
      >
        <b-row class="align-items-center">
          <b-col md="4">
            <!-- statut -->
            <b-form-group
              :label="$t('FILTER_USER_LIST')"
            >
              <b-form-input
                v-model="filter.selectedUser"
                name="user"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="2"
          >
            <b-button
              variant="outline-success"
              @click="filterData"
            >
              {{ $t('FILTER_BUTTON') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-card>

    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('MENU_LIST_USER') }}
        </b-card-title>
      </b-card-header>
      <vue-good-table
        :columns="columns"
        :rows="filteredUsers"
        :fixed-header="true"
        :search-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          {{ $t(props.column.label) }}
        </template>

        <template
          slot="table-row"
          slot-scope="data"
        >
          <span v-if="data.column.field === 'status'">
            <center v-if="data.row.status === true">
              <template>
                <b-button
                  class="btn-success"
                  @click="updateUser(data.row)"
                >
                  {{ $t('USER_ACTIVE') }}
                </b-button>
              </template>

            </center>
            <center v-else-if="data.row.status === false">
              <template>
                <b-button
                  class="btn-danger"
                  @click="updateUser(data.row)"
                >
                  {{ $t('USER_NON_ACTIVE') }}
                </b-button>
              </template>
            </center>

          </span>
          <span
            v-else-if="data.column.field === 'roles'"
            class="text-nowrap"
          >
            <template>
              <b-avatar
                :variant="variantRole(data.row.roles[0]).variant"
                size="30"
              >
                <feather-icon
                  :icon="variantRole(data.row.roles[0]).icon"
                />
              </b-avatar>
              <span class="role"> {{ $t(data.row.roles[0]) }}</span>
            </template>
          </span>
          <span
            v-else-if="data.column.field === 'phone'"
            class="text-nowrap"
          >
            <template>
              <b-avatar
                variant="light-warning"
                size="30"
              >
                <feather-icon
                  icon="PhoneIcon"
                />
              </b-avatar>
              <span class="phone"> {{ $t(data.row.phone) }}</span>
            </template>
          </span>
          <span v-else-if="data.column.field === 'company'">
            <template v-if="data.row.company">
              {{ data.row.company.name }}
            </template>
          </span>

        </template>

        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('PAGINATION_SHOWING') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','25', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> {{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ props.total }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>

</template>

<script>
import { VueGoodTable } from 'vue-good-table'

import {
  BOverlay, BRow, BCol, BAvatar, BCard, BCardHeader, BButton, BCardTitle, BForm, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BCardTitle,
    BFormInput,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    showOverlay: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'HEADER_USER_EMAIL',
          field: 'email',
          filterable: true,
          sortable: true,
          width: '200px',
        },
        {
          label: 'HEADER_USER_FIRSTNAME',
          field: 'firstName',
          filterable: true,
          sortable: true,
          width: '200px',
        },
        {
          label: 'HEADER_USER_LASTNAME',
          field: 'lastName',
          filterable: true,
          sortable: true,
          width: '120px',
        },
        {
          label: 'HEADER_USER_STATUS',
          field: 'status',
          filterable: true,
          type: 'Boolean',
          width: '150px',
        },
        {
          label: 'HEADER_USER_PHONE',
          field: 'phone',
          filterable: true,
          sortable: true,
          width: '200px',
        },
        {
          label: 'HEADER_USER_COMPANY',
          field: 'company',
          filterable: true,
          width: '200px',
        },
        {
          label: 'HEADER_USER_ROLE',
          field: 'roles',
          filterable: true,
          width: '200px',
        },
      ],
      filter: {
        selectedUser: '',
      },
      pageLength: 10,

    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getusers']
    },
    filteredUsers() {
      return this.$store.getters['users/filteredUser']
    },
  },
  methods: {
    filterData() {
      if (this.filter.selectedUser !== null) {
        this.$store.dispatch('users/filter', this.filter.selectedUser)
      }
    },
    updateUser(data) {
      this.$store.dispatch('users/updateUserStatus', data)
        .then(response => {
          if (response.status === 200) {
            const { status } = JSON.parse(response.config.data)
            this.$swal(
              {
                title: this.modalContent(status, data),
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              },
            )
          }
        })
    },
    variantRole(role) {
      let variant = ''
      let icon = ''
      if (role) variant = 'light-'
      if (role === 'ROLE_ADMIN') {
        variant += 'info'
        icon += 'ServerIcon'
      } else if (role === 'ROLE_USER') {
        variant += 'success'
        icon += 'UserIcon'
      } else if (role === 'ROLE_TECHNICIAN') {
        variant += 'success'
        icon += 'SettingsIcon'
      } else {
        variant += 'success'
        icon += 'UsersIcon'
      }
      return { variant, icon }
    },
    modalContent(status, data) {
      const userInfo = `${data.firstName} ${data.lastName}`
      let contents = ''
      if (status) {
        contents += `${this.$i18n.t('USER_STATUS')} ${userInfo} ${this.$i18n.t('USER_STATUS_CHANGED_ACTIVATED')}`
      } else {
        contents += `${this.$i18n.t('USER_STATUS')} ${userInfo} ${this.$i18n.t('USER_STATUS_CHANGED_DEACTIVATED')}`
      }
      return contents
    },
  },
}
</script>
<style scoped lang="scss">
body.dark-layout .vgt-wrap .vgt-table td span.badge {
  color: #fff;
}
.phone{
  margin-left:5px;
}
</style>
