<template>
  <usersFilter
    :title="$t('USER_LIST')"
    :display-filter="true"
    :show-overlay="showOverlay"
  />
</template>

<script>
import usersFilter from './list/filterListing.vue'

export default {
  components: {
    usersFilter,
  },
  data() {
    return {
      showOverlay: false,
    }
  },
  computed: {
    getusers() {
      return this.$store.getters['users/getusers']
    },
  },
  mounted() {
    this.showOverlay = true
    this.$store.dispatch('users/fetchAllUsers')
      .then(response => {
        this.allUsers = response
        this.showOverlay = false
      }).catch(() => {
        this.showOverlay = false
        this.$router.push('/pages/error-404')
      })
  },
}
</script>
